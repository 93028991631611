/* General Styling */
html, body {
    margin: 0;
    padding: 0;
    background-color: black;
    color: white;
    min-height: 100%;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
  }
